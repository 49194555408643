/* Imports */
@import 'styles/index';


/* Styles */
.vendor {
  padding: 40px 0 20px 0;

  .icon,
  .name {
    display: inline-block;

    vertical-align: top;
  }

  .icon {
    position: relative;

    width: 80px;
    height: 80px;

    margin-right: 20px;
    padding: 10px;

    background-color: $color-white;

    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    background-origin: content-box;
  }

  .name {

  }
}

.content {
  background-color: #1e2329;
}

.apiTabs {
  position: relative;

  background-color: #13161a;

  &:before {
    content: '';

    position: absolute;

    left: 0;
    right: 0;
    top: 0;

    height: 4px;

    background-color: #1b1f25;
  }

  .apiTab {
    @include transition-all;

    position: relative;

    display: inline-block;

    padding: 13px 20px;

    font-size: px-to-em(16px);

    color: #6f788a;

    border-top: 4px solid transparent;

    border-right: 2px solid #1e2329;

    text-transform: uppercase;

    z-index: 1;

    user-select: none;

    -webkit-user-drag: none;

    &:last-of-type {
      border-right: none;
    }

    &.active,
    &:hover {
      color: #b8bcc3;
    }

    &.active {
      background-color: #1e2329;

      border-top: 4px solid #1e2329;
    }

    .lock {
      display: inline-block;

      width: 12px;
      height: 12px;

      margin-left: 10px;

      background-image: url('../../../images/icon-lock-opened-gray.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      &.locked {
        background-image: url('../../../images/icon-lock-locked-gray.svg');
      }
    }
  }
}

.api {
  padding: 33px 20px 31px 20px;
}

.tabs {
  @include clear-fix;

  margin: 0 20px 40px 20px;

  border-bottom: 1px solid #2f3542;

  background-color: #1e2329;

  .tab {
    @include transition-all;

    margin-right: 30px;
    padding: 24px 0;

    font-size: px-to-em(16px);
    font-weight: $font-weight-medium;

    color: #616b7a;

    border: 2px solid transparent;

    float: left;

    &:last-of-type {
      margin-right: 0;
    }

    &:hover,
    &.active {
      color: $color-white
    }

    &.active {
      border-bottom-color: #E83000;
    }
  }
}
