.overlay {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: rgba(0, 0, 0, 0.8);

  z-index: 100;
}

.content {
  position: absolute;

  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;

  width: 100%;
  max-width: 630px;

  padding: 115px 100px;
  background-color: #13161A;

  outline: none;

  transform: translate(-50%, -50%);

  // todo
  @media only screen and (max-width: 768px) {
    padding: 40px 20px;
  }
}


