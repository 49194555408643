@import 'styles/index';

.bg {
  position: absolute;

  top: 89px;
  left: 20px;
  right: 20px;

  height: 812px;

  background-image: url('../../../images/hero.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 1600px 812px;

  // todo
  @media only screen and (max-width: 768px) {
    background-image: none;
  }
}

.hero {
  position: relative;

  padding: 172px 0;

  text-align: center;

  // todo
  @media only screen and (max-width: 768px) {
    padding: 80px 0;
  }

  .title {
    position: relative;

    max-width: 735px;

    margin: 0 auto 90px auto;

    font-size: px-to-em(56px);

    z-index: 1;

    // todo
    @media only screen and (max-width: 768px) {
      font-size: px-to-em(36px);
    }

    .light {
      font-weight: $font-weight-light;

      color: #9b9fa5;
    }

    .bold {
      color: #ffffff;
    }
  }

  .description {
    position: relative;

    max-width: 570px;

    margin: 0 auto;

    font-size: px-to-em(24px);

    color: #6f788a;

    z-index: 1;
  }
}
