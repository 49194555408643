@font-face {
  font-family: 'metropolis';
  src: url('../fonts/metropolis-light-webfont.woff2') format('woff2'),
  url('../fonts/metropolis-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'metropolis';
  src: url('../fonts/metropolis-regular-webfont.woff2') format('woff2'),
  url('../fonts/metropolis-regular-webfont.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'metropolis';
  src: url('../fonts/metropolis-medium-webfont.woff2') format('woff2'),
  url('../fonts/metropolis-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'metropolis';
  src: url('../fonts/metropolis-semibold-webfont.woff2') format('woff2'),
  url('../fonts/metropolis-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'metropolis';
  src: url('../fonts/metropolis-bold-webfont.woff2') format('woff2'),
  url('../fonts/metropolis-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
