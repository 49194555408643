@import 'styles/index';

.note {

  padding: 20px;

  color: #e5e5e5;

  background-color: #242830;
}

.form,
.apis {
  display: inline-block;

  vertical-align: top;
}

.form {
  width: 360px;
  padding: 20px;

  background-color: #232831;
}
