@mixin transition-all {
  transition-property: all;
  transition-duration: 0.1s;
  transition-timing-function: linear;
}

@mixin clear-fix {
  &:after {
    display: block;
    content: "";
    clear: both;
  }
}
