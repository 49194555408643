@import 'styles/index';

.content {
  position: relative;

  margin-bottom: 40px;

  .title {
    font-size: px-to-em(16px);

    color: #6f788a;
  }

  .field,
  .copy,
  .show {
    display: inline-block;
  }

  .field {
    @include transition-all;

    display: inline-block;

    width: 100%;

    padding: 25px 90px 25px 20px;

    background-color: transparent;

    font-size: px-to-em(16px);

    color: #6f788a;

    border: none;
    outline: none;

    border-bottom: 1px solid #656d7e;

    &:focus {
      color: $color-white;

      border-bottom: 1px solid #cfd4df;
    }
  }

  .show {
    position: absolute;

    right: 50px;
    bottom: 28px;

    width: 18px;
    height: 14px;

    background-image: url('../../../images/icon-eye.svg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;

    cursor: pointer;

    &.hidden {
      background-image: url('../../../images/icon-eye-locked.svg');
    }

    &:hover {
      opacity: 0.9;
    }
  }

  .copy {
    @include transition-all;

    position: absolute;

    right: 20px;
    bottom: 28px;


    width: 12px;
    height: 14px;

    background-image: url('../../../images/icon-copy.svg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: contain;

    cursor: pointer;

    &:hover {
      opacity: 0.9;
    }
  }


}
