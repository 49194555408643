@import "variables";

html {
  font-size: $default-font-size;
}

body {
  margin: 0;
  padding: 0;

  background-color: #1b1f25;

  font-family: metropolis, sans-serif;
  font-weight: $font-weight-regular;

  line-height: 1.2;
}

button,
input,
optgroup,
select,
textarea {
  font-family: metropolis, sans-serif;
  //font-family: 'Aldrich', sans-serif;
}

