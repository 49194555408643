@import 'styles/index';

.vendors {
  //display: inline-block;
  //
  //vertical-align: top;

  display: inline-flex;

  flex-wrap: wrap;

  width: calc(100% - 360px);

  //margin: 0 -10px;

  padding-left: 10px;

  @media only screen and (max-width: 768px) {
    display: block;

    width: 100%;

    margin: 0;
    padding: 0;
  }
}
