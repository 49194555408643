@import 'styles/index';

.vendor {
  display: flex;

  width: 25%;

  margin-bottom: 20px;
  padding: 0 10px;

  &:nth-of-type(4n) {
    padding-right: 0;
  }

  @media only screen and (max-width: 1280px) {
    width: 50%;

    &:nth-of-type(2n) {
      padding-right: 0;
    }
  }

  @media only screen and (max-width: 1024px) {
    width: 100%;

    padding-right: 0;
  }

  @media only screen and (max-width: 768px) {
    padding: 0;
  }

  .content {
    display: flex;

    flex-direction: column;

    width: 100%;

    padding: 20px;

    background-color: #242830;

    text-align: center;

    .name {
      flex-grow: 1;

      margin-bottom: 10px;

      font-weight: $font-weight-semi-bold;
      font-size: px-to-em(16px);

      color: $color-white;

      word-wrap: break-word;
    }

    .image {
      width: 100%;
      height: 100px;

      margin: 0 auto 15px auto;

      padding: 10px;

      background-color: $color-white;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-origin: content-box;
    }

    .button {
      @include transition-all;

      display: flex;

      align-items: center;
      justify-content: center;

      padding: 8px 11px;

      font-weight: $font-weight-medium;
      font-size: px-to-em(14px);

      color: $color-white;

      border: 1px solid #313944;

      cursor: pointer;

      .arrow {
        width: 12px;
        height: 8px;

        margin-right: 5px;

        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;

        background-image: url('../../../../../images/icon-arrow-left.svg');
      }

      &:hover {
        background-color: $color-white;

        color: #1b1f25;

        border: 1px solid $color-white;
      }
    }
  }
}
