@import 'styles/index';
.label {
  @include clear-fix;

  display: inline-block;

  width: 100%;

  margin-bottom: 40px;
}

.label .title {
  //display: inline-block;

  //width: 100%;

  font-size: px-to-em(16px);

  color: #6f788a;

  float: left;
}

.label {
  @include clear-fix;

  display: inline-block;

  width: 100%;

  margin-bottom: 40px;

  .title {
    //display: inline-block;

    //width: 100%;

    font-size: px-to-em(16px);

    color: #6f788a;

    float: left;
  }

  .error {
    float: right;

    color: #f04235;
  }

  .input {
    display: inline-block;

    width: 100%;

    padding: 25px 20px;

    background-color: transparent;

    font-size: px-to-em(16px);

    color: #6f788a;

    border: none;
    outline: none;

    border-bottom: 1px solid #656d7e;

    &:focus {
      color: $color-white;

      border-bottom: 1px solid #cfd4df;
    }
  }

  &.hasError {
    .title {
      color: #f04235;
    }

    .input {
      border-bottom: 1px solid #f04235;
    }

  }
}
