$default-font-size: 14px;

$default-line-height: 1.2;

$default-border-radius: 8px;

$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;

$color-white: #ffffff;
$color-black: #0d0b11;

$color-orange-dark: #f04235;
