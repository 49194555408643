@import 'styles/index';

.header {
  position: fixed;

  top: 0;
  left: 0;
  right: 0;

  height: 60px;

  background-color: #13161a;

  z-index: 5;
}

.logo {
  display: inline-block;

  width: 120px;
  height: 24px;

  margin: 18px 10px 18px 0;

  background-image: url('../../../images/logo.svg');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: contain;

  float: left;
}

.nav {
  @include transition-all;

  display: table;

  height: 60px;

  float: right;

  // todo
  @media only screen and (max-width: 1024px) {
    position: fixed;

    top: 0;
    right: -200px;

    display: block;

    width: 200px;
    height: 100%;

    margin-top: 60px;

    background-color: #2d343d;

    &.show {
      right: 0;
    }
  }

  .navItemWrap {
    position: relative;

    display: table-cell;

    padding: 0 8px;

    vertical-align: top;

    @media only screen and (max-width: 1024px) {
      display: block;

      padding: 0;
    }

    .navItem {
      @include transition-all;

      display: table-cell;

      height: 60px;

      margin-right: 30px;

      font-size: px-to-em(14px);

      color: #838c9a;

      vertical-align: middle;

      cursor: pointer;

      // todo
      @media only screen and (max-width: 1024px) {
        display: block;

        width: 100%;
        height: auto;

        padding: 17px 15px;
      }

      &.collapse {
        // padding-right: 20px;

        &:after {
          @include transition-all;

          content: '';

          position: relative;

          top: 3px;

          display: inline-block;

          width: 0;
          height: 0;

          margin-left: 10px;

          border-width: 5px;
          border-style: solid;
          border-color: #838c9a transparent transparent transparent;
        }
      }

      &:hover {
        color: #e4e4e4;

        &:after {
          border-color: #e4e4e4 transparent transparent transparent;
        }
      }

      &.active {
        color: $color-white;
      }
    }
  }
}

.button {
  position: fixed;

  top: 0;
  right: 0;

  display: none;

  width: 64px;
  height: 64px;

  background-image: url('../../../images/hamburger.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 36px 36px;

  cursor: pointer;

  // todo
  @media only screen and (max-width: 1024px) {
    display: block;
  }
}
