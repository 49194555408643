.markdown {
  color: #616b7a;

  /*h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #8796aa;
  }*/

  table {
    border-collapse: collapse;

    th,
    td {
      padding: 10px 15px;
      border: 1px solid #4b4b4b;
    }

    th {
      text-align: left;
      font-weight: bold;
    }
  }

  pre {
    font-family: Courier, monospace;
    white-space: pre;
    background-color: #263238;
    color: #ffffff;
    padding: 20px;
    overflow-x: auto;
    line-height: normal;

    code {
      background-color: transparent;
      color: #ffffff;
      padding: 0;
    }
  }

  code {
    color: #e53935;
    background-color: rgba(38,50,56,0.05);
    font-family: Courier, monospace;
    border-radius: 2px;
    border: 1px solid rgba(38,50,56,0.1);
    padding: 0 5px;
    font-size: 13px;
    font-weight: 400;
    word-break: break-word;
  }

  a {
    color: #e56633;

    &:hover {
      text-decoration: underline;
    }
  }
}
