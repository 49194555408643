@import 'styles/index';

.content {
  position: absolute;

  left: 50%;

  width: 160px;

  //padding: 0 10px;

  background-color: #262C34;

  white-space: nowrap;

  transform: translate(-50%);

  .item {
    @include transition-all;

    padding: 15px 30px;

    font-size: px-to-em(14px);

    color: #838c9a;

    text-align: center;

    text-transform: uppercase;

    cursor: pointer;

    &:hover {
      color: $color-white;

      background-color: #2F3640;
    }
  }
}
