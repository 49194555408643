@import 'styles/index';


.controls {
  position: relative;

  display: flex;

  justify-content: flex-end;

  margin-bottom: 35px;
  padding: 15px 20px;

  background-color: rgba(0, 0, 0, 0.3);

  .control {
    //todo
  }
}

.list {
  position: relative;

  display: flex;

  flex-wrap: wrap;

  margin: 0 -10px;

  z-index: 1;
}
