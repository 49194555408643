@import 'styles/index';

.apiItem {
  @include transition-all;

  position: relative;

  display: flex;

  flex-direction: column;

  width: 20%;

  margin: 0 0 35px 0;
  padding: 0 10px;

  // todo
  @media only screen and (max-width: 1320px) {
    width: 25%;
  }

  @media only screen and (max-width: 1024px) {
    width: 50%;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  .image {
    @include transition-all;

    height: 200px;

    margin-bottom: 15px;
    padding: 20px;

    background-color: #ffffff;

    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-origin: content-box;

    opacity: 0.8;
    filter: grayscale(100%);
  }

  .imageSubstrate {
    position: absolute;

    top: 0;

    left: 0;
    right: 0;

    width: 100%;
    height: 200px;

    background-color: #1b1f25;
  }

  .lock {
    position: absolute;

    top: 15px;
    right: 25px;

    width: 24px;
    height: 24px;

    background-image: url('../../../images/icon-lock-opened-white.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    &.locked {
      background-image: url('../../../images/icon-lock-locked-white.svg');
    }
  }

  .info {
    position: relative;

    display: flex;

    flex-direction: column;

    flex-grow: 1;

    padding: 27px 24px;

    margin-bottom: 15px;

    background-color: #1b1f25;

    border: 1px solid #2b333d;

    text-transform: uppercase;

    .name {
      @include transition-all;

      flex-grow: 1;

      // margin-bottom: 25px;

      font-size: px-to-em(16px);
      font-weight: $font-weight-medium;
      color: #b6b7b9;
    }

    .category {
      margin-top: 25px;

      font-size: px-to-em(14px);

      color: #616b7a;
    }
  }

  &:hover {
    .image {
      opacity: 1;

      filter: none;
    }

    .lock {
      background-image: url('../../../images/icon-lock-opened-gray.svg');

      &.locked {
        background-image: url('../../../images/icon-lock-locked-gray.svg');
      }
    }

    .angle {
      border-color: #f04235;
    }
  }
}

.angle {
  @include transition-all;

  position: absolute;

  width: 20px;
  height: 20px;

  border: 1px solid #465364;

  &.topLeft {
    top: -1px;
    left: -1px;

    border-bottom-color: transparent!important;
    border-right-color: transparent!important;
  }

  &.topRight {
    top: -1px;
    right: -1px;

    border-bottom-color: transparent!important;
    border-left-color: transparent!important;
  }

  &.bottomLeft {
    bottom: -1px;
    left: -1px;

    border-top-color: transparent!important;
    border-right-color: transparent!important;
  }

  &.bottomRight  {
    bottom: -1px;
    right: -1px;

    border-top-color: transparent!important;
    border-left-color: transparent!important;
  }
}


