/* Imports */
@import 'styles/index';

/* Styles */
.title {
  margin-bottom: 40px;

  font-size: px-to-em(30px);

  color: $color-white;

  text-align: center;
}

.note {
  margin-bottom: 70px;

  font-size: px-to-em(20px);

  color: #9b9fa5;

  text-align: center;
}

.form {
  margin-bottom: 50px;
}

.switch {
  text-align: center;

  .text,
  .button {
    display: inline-block;
  }

  .text {
    margin-right: 20px;

    color: #6f788a;
  }

  .button {
    color: $color-white;

    cursor: pointer;
  }
}
