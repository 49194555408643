@import 'styles/index';

.awayLink {
  @include transition-all;

  display: inline-block;

  color: #838c9a;

  font-size: px-to-em(20px);

  text-transform: uppercase;

  &:hover {
    color: $color-white;

    .icon {
      background-image: url('../../../images/icon-away-active.svg');
    }
  }

  .text,
  .icon {
    display: inline-block;

    vertical-align: middle;
  }

  .text {
    display: inline-block;
  }

  .icon {
    @include transition-all;

    display: inline-block;

    width: 17px;
    height: 17px;

    margin-left: 10px;

    background-image: url('../../../images/icon-away.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
}


