.redoc {

  background-color: #1e2329;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #8796aa;

    * {
      color: #8796aa;
    }
  }
}


