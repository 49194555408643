@import 'styles/index';

.bundles {
  margin: 0 -10px;

  .bundle {
    @include transition-all;

    width: calc((100% / 3));

    margin-bottom: 20px;

    @media only screen and (max-width: 1280px) {
      width: 50%;
    }

    @media only screen and (max-width: 1024px) {
      width: 100%;
    }

    .content {
      margin: 0 10px;
      padding: 20px;

      background-color: #242830;

      .name {
        margin-bottom: 10px;

        font-weight: $font-weight-semi-bold;
        font-size: px-to-em(16px);

        color: $color-white
      }

      .bundleInfo {
        margin-bottom: 10px;

        .author,
        .created {
          display: inline-block;

          vertical-align: bottom;
        }

        .author {
          margin-right: 10px;

          font-weight: $font-weight-bold;
          font-size: px-to-em(14px);

          color: $color-orange-dark;
        }

        .created {
          font-size: px-to-em(12px);

          color: #7a8294;
        }
      }

      .description {
        margin-bottom: 20px;

        font-weight: $font-weight-light;
        font-size: px-to-em(14px);

        color: #7a8294;
      }

      .title {
        font-weight: $font-weight-semi-bold;

        color: #a5aec3;

        opacity: 0.9;

        margin-bottom: 10px;
      }

      .table {
        position: relative;

        margin-bottom: 20px;
        border: 1px solid #242830;

        background-color: #1b1f25;


        &:hover {
          .angle {
            border-color: #f04235;
          }
        }

        .row {
          display: flex;

          &:not(:last-of-type) {
            border-bottom: 1px solid #242830;
          }

          .cell {
            @include transition-all;

            position: relative;

            padding: 10px;

            color: #7a8294;

            &:first-of-type {
              width: 30%;

              font-weight: $font-weight-semi-bold;

              color: #9ca6bd;

              border-right: 1px solid #242830;
            }

            &:last-of-type {
              width: 70%;

              font-weight: $font-weight-light;
            }
          }

          &:hover {
            .cell {
              &:first-of-type {
                color: $color-white;
              }

              &:last-of-type {
                color: $color-white;
              }
            }
          }
        }
      }

      .apis {
        display: flex;

        flex-wrap: wrap;

        margin: 0 -5px;

        .api {
          width: calc(50% - 10px);

          margin: 0 5px 10px 5px;

          padding: 15px 10px;

          background-color: #1b1f25;

          @media only screen and (max-width: 768px) {
            width: 100%;
          }

          .apiInfo {
            display: flex;

            align-items: center;

            justify-content: right;

            margin-bottom: 8px;

            .apiImage {
              width: 40px;
              height: 40px;

              flex: 0 0 40px;

              padding: 5px;

              background-color: $color-white;

              background-position: center;
              background-repeat: no-repeat;
              background-size: contain;
              background-origin: content-box;
            }

            .apiLink {
              @include transition-all;

              display: flex;

              width: 100%;
              //height: 40px;

              margin-left: 20px;

              align-items: center;
              justify-content: center;

              font-size: px-to-em(11px);

              text-align: center;

              padding: 8px 2px;

              font-weight: $font-weight-medium;

              color: $color-white;

              border: 1px solid #313944;

              cursor: pointer;

              &:hover {
                background-color: $color-white;

                color: #1b1f25;

                border: 1px solid $color-white;
              }
            }
          }

          .apiName {
            margin-bottom: 10px;

            font-weight: $font-weight-semi-bold;
            font-size: px-to-em(14px);

            color: $color-white;

            text-align: right;

            word-break: break-word;
          }

          .apiRole {
            font-weight: $font-weight-light;
            font-size: px-to-em(14px);

            color: #7a8294;

            text-align: right;

            word-break: break-word;
          }
        }
      }
    }

    .deploy {
      display: block;

      margin: 10px 10px 0 10px;
    }
  }
}

.angle {
  @include transition-all;

  position: absolute;

  width: 12px;
  height: 12px;

  border: 1px solid #465364;

  &.topLeft {
    top: -1px;
    left: -1px;

    border-bottom-color: transparent!important;
    border-right-color: transparent!important;
  }

  &.topRight {
    top: -1px;
    right: -1px;

    border-bottom-color: transparent!important;
    border-left-color: transparent!important;
  }

  &.bottomLeft {
    bottom: -1px;
    left: -1px;

    border-top-color: transparent!important;
    border-right-color: transparent!important;
  }

  &.bottomRight  {
    bottom: -1px;
    right: -1px;

    border-top-color: transparent!important;
    border-left-color: transparent!important;
  }
}
