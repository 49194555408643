@import 'styles/index';

.button {
  @include transition-all;

  display: inline-block;

  padding: 16px 20px;

  color: $color-white;

  border: none;
  outline: none;

  white-space: nowrap;

  cursor: pointer;

  // sizes
  &.small {
    padding: 7px 24px;
  }

  &.regular {
    padding: 14px 24px;
  }

  &.large {
    padding: 17px 24px;
  }

  // colors
  &.gray {
    background: #313944;

    &.active,
    &:hover {
      background: #f04235;
    }
  }

  &.orange {
    background: #ff6947;

    &.active,
    &:hover {
      background: #ff8a70;
    }
  }

  &.red {
    background: #f04235;

    &.active,
    &:hover {
      background: #ff7d4d;
    }
  }

  &.fluid {
    width: 100%;
  }

  &:disabled {
    background-color: gray!important;

    cursor: not-allowed!important;
  }
}


