@import 'styles/index';

.tab {
  @include transition-all;

  display: inline-block;

  margin-right: 30px;
  padding: 24px 0;

  font-size: px-to-em(16px);
  font-weight: $font-weight-medium;

  color: #616b7a;

  border: 2px solid transparent;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover,
  &.active {
    color: $color-white
  }

  &.active {
    border-bottom-color: #E83000;
  }
}
