@import 'styles/index';

.loading {
  position: absolute;

  display: flex;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  justify-content: center;
  align-items: center;

  background-color: #1b1f25;

  z-index: 100;

  .icon {
    //width: 256px;
    //height: 256px;

    width: 20%;
    height: 20%;

    background-image: url('../../../images/icon.svg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;

    animation-name: zoom;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }
}


@keyframes zoom {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1.5);
  }
}


