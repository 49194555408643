@import '../../../styles/index';

.form {
  display: inline-block;

  vertical-align: top;

  width: 360px;
  padding: 20px;

  background-color: #232831;

  @media only screen and (max-width: 768px) {
    width: 100%;

    margin-bottom: 20px;
  }

  .block {
    padding-top: 20px;
    // border-top: 1px solid #292f39;
  }

  .buttons {
    text-align: right;

    .button {
      display: inline-block;
      &:not(:last-of-type) {
        margin-right: 20px;
      }
    }
  }
}

.title {
  margin-bottom: 10px;


  font-weight: $font-weight-semi-bold;
  font-size: px-to-em(14px);

  color: #7a8294;
}

.error {
  color: #f04235;

  text-align: right;
}

.label {
  display: block;
  margin-bottom: 10px;
}

.field {
  @include transition-all;

  display: inline-block;

  width: 100%;

  padding: 10px 15px;

  background-color: #292f3a;

  font-weight: $font-weight-light;
  font-size: px-to-em(14px);

  color: $color-white;

  border: 1px solid transparent;
  //border: none;
  outline: none;
  resize: none;

  &.textarea {
    height: 90px;
  }

  &:hover {
    background-color: #313944;
  }

  &:focus {
    background-color: #313944;

    //border: 1px solid $color-black;

    box-shadow: 0 0 6px rgba(12, 12, 12, 0.9);
  }

  &::placeholder {
    color: #7a8294;
  }
}

.search {
  position: relative;

  margin-bottom: 20px;

  .dropdown {
    position: absolute;

    left: 0;
    right: 0;


    background-color: #303845;

    .dropdownItem {
      display: flex;

      padding: 3px 15px;

      font-weight: $font-weight-light;
      font-size: px-to-em(16px);

      color: #7a8294;

      .dropdownImage {
        display: inline-block;

        width: 24px;
        height: 24px;

        margin-right: 10px;
        padding: 2px;

        background-color: $color-white;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        background-origin: content-box;
      }

      .dropdownName {

      }

      &:hover {
        background-color: #3E4859;

        color: $color-white
      }
    }
  }
}

.apis {

  .api {
    @include transition-all;

    margin-bottom: 20px;

    .image,
    .content {
      display: inline-block;

      vertical-align: top;
    }

    .image {
      width: 100px;
      height: 100px;

      padding: 10px;

      background-color: $color-white;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-origin: content-box;
    }

    .content {
      width: calc(100% - 100px);

      padding-left: 10px;

      .name {
        margin-bottom: 7px;

        font-weight: $font-weight-semi-bold;
        font-size: px-to-em(14px);

        color: #7a8294;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .role {

      }

      .button {
        text-align: right;
      }
    }

    &:not(:last-of-type):after {
      content: '';

      display: block;

      //width: 21px;
      height: 21px;

      margin-top: 20px;


      background-image: url('../../../images/connector.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
    }

  }
}
